<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{icons.mdiAccountMultiplePlus}}</v-icon> <span class="primary--text">{{$t('menu.evidenceType')}}</span>
      <v-spacer></v-spacer>
      <v-btn 
        class="primary mt-2" 
        medium
        rounded
        @click.stop="dialog = true"
      >
        <v-icon left>{{icons.mdiPlusThick}}</v-icon> {{$t('button.newEvidenceType')}}
      </v-btn>
    </v-card-title>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-icon left>{{icons.mdiAccount}}</v-icon> {{ dialogTitle }}
        </v-card-title>
        <br />
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  :label="$t('text.letterTypeNameKh')"
                  :append-icon="icons.mdiAccount"
                  dense
                  v-model="caseEvidenceType.evidenceTypeNameKh"
                  :rules="rule.requiredField()"
                ></v-text-field>  
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  :label="$t('text.letterTypeNameEn')"
                  :append-icon="icons.mdiAccount"
                  v-model="caseEvidenceType.evidenceTypeNameEn"
                  dense
                ></v-text-field>  
              </v-col>          
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            @click="save"
          >
            <v-icon dark left>{{icons.mdiContentSave}}</v-icon> {{$t('button.save')}}
          </v-btn>
          <v-btn
            class="secondary"
            @click="dialog = false"
          >
            <v-icon left>{{icons.mdiCancel}}</v-icon> {{$t('button.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="caseEvidenceTypeList"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-2" @click="editItem(item)">
            {{icons.mdiPencil}}
          </v-icon>
          <v-icon medium @click="deleteItem(item)">
            {{icons.mdiDelete}}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPencil, mdiDelete, mdiContentSave, mdiCancel, mdiMagnify, mdiPlusThick } from '@mdi/js';
import SystemService from '@/services/service.system';
import Rule from '@/plugins/rules.js';

export default {
  name: 'case-letter-type',
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiContentSave,
        mdiCancel,
        mdiMagnify,
        mdiPlusThick,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Evidence Type (KH)', value: 'evidenceTypeNameKh' },
        { text: 'Evidence Type (EN) ', value: 'evidenceTypeNameEn' },
        { text: 'ACTION', value: 'actions', sortable: false },
      ],
      rule: [],
      letterTypeList: [],
      caseLetterTypeList: [],
      caseLetterType: {},
      caseEvidenceTypeList: [],
      caseEvidenceType: {},
      dialog: false,
      dialogDelete: false,
      search: '',
      resultMessage: '',
      editedIndex: -1,
    };
  },
  created() {
    this.rule = Rule;
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? this.$t('caption.newEvidenceType') : this.$t('caption.editEvidenceType');
    },
    selectedItems: {
      get() {
        return this.value;
      },
      set(item) {
        this.chosenItems.push(item);
        this.$emit('input', item);
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    SystemService.getCaseEvidenceTypeAll().then(response => {
      this.caseEvidenceTypeList = response.data;
    });
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.caseEvidenceTypeList.indexOf(item);
      this.caseEvidenceType = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.$confirm({
        message: 'Do you want to delete ' + item.evidenceTypeNameKh + '?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            SystemService.deleteCaseLetterTypeById(item.id).then(
              () => {
                const index = this.caseEvidenceTypeList.indexOf(item);
                this.caseEvidenceTypeList.splice(index, 1);
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: 'Position has been deleted',
                });
              },
              error => {
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'error',
                  text: error.response ? error.response.data : 'An error occurs',
                });
              },
            );
          }
        },
      });
    },
    save() {
      if (!this.$refs.form.validate()) return;
      SystemService.insertCaseEvidenceType(this.caseEvidenceType).then(
        response => {
          if (response.data) {
            if (this.editedIndex == -1) {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.addedCaseLetterType'),
              });

              this.caseEvidenceTypeList.push(response.data);
            } else {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.updatedCaseLetterType'),
              });
              Object.assign(this.caseEvidenceTypeList[this.editedIndex], response.data);
            }
            this.dialog = false;
          }
        },
        error => {
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: error.response ? error.response.data : 'An error occurs',
          });
        },
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.caseEvidenceType = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
